import React from 'react';
import { Row, Col, Card, Typography, Tag, Button } from 'antd';

const { Title, Paragraph } = Typography;

const Projects = ({ projects }) => (
    <section id="projects" style={{ padding: '50px 20px' }}>
        {/* Section Header */}
        <Title level={2} style={{ textAlign: 'center', color: 'var(--secondary-color)' }}>
            Projects
        </Title>

        {/* Project Description */}
        <Row gutter={[16, 32]} justify="center">
            <Col xs={24} sm={24} md={24} lg={20} xl={16}>
                <Paragraph style={{ textAlign: 'center', fontWeight: 'bold' }}>
                    I have worked on several programming projects. They were either part of my coursework or personal ideas.
                    They represent milestones that have helped me gain a better understanding of the software development process.
                    I readily admit that most of them could be quickly recreated using AI nowadays. It was more about the challenge of understanding the basic set of tools.
                </Paragraph>
            </Col>

            {/* Project Cards */}
            {projects.map((project, index) => (
                <Col xs={24} sm={24} md={24} lg={20} xl={16} key={index} style={{ marginBottom: '20px' }}>
                    <Card
                        style={{
                            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                            borderRadius: '8px',
                            overflow: 'hidden',
                        }}
                    >
                        <Row
                            gutter={[16, 16]} // Spacing between image and text
                            align="middle"
                            justify="center"
                        >
                            {/* Project Image */}
                            <Col xs={24} md={8} style={{ textAlign: 'center' }}>
                                <div
                                    style={{
                                        width: '100%',
                                        maxWidth: '200px',
                                        height: '200px',
                                        overflow: 'hidden',
                                        borderRadius: '8px',
                                        margin: '0 auto', // Center on smaller screens
                                    }}
                                >
                                    <img
                                        alt={project.title}
                                        src={project.image}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                            objectPosition: 'center',
                                        }}
                                    />
                                </div>
                            </Col>

                            {/* Project Details */}
                            <Col xs={24} md={16}>
                                <Title level={4} style={{ marginBottom: '10px', marginTop: 0 }}>
                                    {project.title}
                                </Title>
                                <Paragraph style={{ fontWeight: 'bold', marginBottom: '10px' }}>
                                    {project.shortDescription}
                                </Paragraph>
                                {/* Technologies as Labels */}
                                <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                                    {project.technologies.split(', ').map((tech, index) => (
                                        <Tag
                                            key={index}
                                            color="var(--secondary-color)"
                                            style={{ marginBottom: '5px' }}
                                        >
                                            {tech}
                                        </Tag>
                                    ))}
                                </div>
                                <Paragraph>{project.longDescription}</Paragraph>
                                {/* Links as Buttons */}
                                <div>
                                    {project.links.map((link, index) => (
                                        <Button
                                            key={index}
                                            type="primary"
                                            href={link.url}
                                            target="_blank"
                                            style={{
                                                marginRight: '10px',
                                                marginBottom: '10px',
                                                backgroundColor: '#008080',
                                            }}
                                        >
                                            {link.label}
                                        </Button>
                                    ))}
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            ))}
        </Row>
    </section>
);

export default Projects;
