import React, { useState } from "react";
import { Typography, Card, Col, Row, Modal } from "antd";
const { Title, Paragraph } = Typography;


const Music = ({ albums }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedAlbum, setSelectedAlbum] = useState(null);

    const showModal = (album) => {
        setSelectedAlbum(album);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setSelectedAlbum(null);
    };

    return (
        <div style={{ padding: "20px" }}>
            <Title level={2} style={{ marginBottom: '30px', textAlign: 'center', color: 'var(--secondary-color)' }}>
                My Favorite Albums
            </Title>
            <Row gutter={[16, 32]} justify="center">
                <Col xs={24} sm={24} md={24} lg={20} xl={16}>
                    <Paragraph style={{ textAlign: 'center', fontWeight: 'bold' }}>
                        Click on an album to see my favourite track from it. You can also directly listen to it from Spotify.
                    </Paragraph>
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                {albums.map((album) => (
                    <Col xs={24} sm={12} md={8} xl={4} key={album.title}>
                        <Card style={{backgroundColor: "var(--octonary-color)"}}
                            hoverable
                            cover={
                                <img
                                    alt={album.title}
                                    src={album.imageUrl}
                                    onClick={() => showModal(album)}
                                    style={{ cursor: "pointer" }}
                                />
                            }
                            onClick={() => showModal(album)}
                        >
                            <Card.Meta
                                title={album.title}
                                description={
                                    <>
                                        <p style={{ color: "var(--tertiary-color)", margin: 0 }}>
                                            <strong>Artist:</strong> {album.artist}
                                            <br />
                                            <strong>Release Year:</strong> {album.releaseYear}
                                            <br />
                                            <strong>Genre:</strong> {album.genre}
                                        </p>
                                    </>
                                }
                            />
                        </Card>
                    </Col>
                ))}
            </Row>

            <Modal
                title={`My Favourite Track: ${selectedAlbum?.fav}`}
                visible={isModalVisible}
                footer={null}
                onCancel={handleCancel}
            >
                <iframe
                    title="Spotify Embed"
                    src={selectedAlbum?.spotifyEmbedUrl}
                    width="100%"
                    height="450px"
                    frameBorder="0"
                    allow="encrypted-media"
                    loading="lazy"
                ></iframe>
            </Modal>
        </div>
    );
};

export default Music;
