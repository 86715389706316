import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css'
import { initializeApp } from 'https://www.gstatic.com/firebasejs/9.12.1/firebase-app.js'

const firebaseConfig = {
  apiKey: 'AIzaSyCW7A-wRwOPLarr_9IhuPDz7JTspMksUhM',
  authDomain: 'homepage-2c9ab.firebaseapp.com',
  projectId: 'homepage-2c9ab',
  storageBucket: 'homepage-2c9ab.appspot.com',
  messagingSenderId: '982330978014',
  appId: '1:982330978014:web:22ad90b835d8b474321c4f',
  measurementId: 'G-417KHR5516'
}

initializeApp(firebaseConfig)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);