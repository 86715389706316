const movieList = [
    {
        title: "The Good, the Bad and the Ugly",
        releaseYear: 1966,
        director: "Sergio Leone",
        genre: "Western",
        imageUrl: "res/artwork/thegood.jpg",
    },
    {
        title: "Koyaanisqatsi",
        releaseYear: 1982,
        director: "Godfrey Reggio",
        genre: "Documentary",
        imageUrl: "res/artwork/koyaanisqatsi.jpg",
    },
    {
        title: "Pappa Ante Portas",
        releaseYear: 1991,
        director: "Loriot",
        genre: "Comedy",
        imageUrl: "res/artwork/pappa.webp",
    },
    {
        title: "Titanic",
        releaseYear: 1997,
        director: "James Cameron",
        genre: "Drama",
        imageUrl: "res/artwork/titanic.jpg",
    },
    {
        title: "The Lord of the Rings",
        releaseYear: "2001-2003",
        director: "Peter Jackson",
        genre: "Fantasy",
        imageUrl: "res/artwork/lotr.jpg",
    },
    {
        title: "Cars",
        releaseYear: 2006,
        director: "John Lasseter",
        genre: "Computer animation",
        imageUrl: "res/artwork/cars.jpg",
    },
    {
        title: "Casino Royale",
        releaseYear: 2006,
        director: "Martin Campbell",
        genre: "Spy film",
        imageUrl: "res/artwork/casino.jpg",
    },
    {
        title: "Wall-E",
        releaseYear: 2008,
        director: "Andrew Stanton",
        genre: "Computer animation",
        imageUrl: "res/artwork/walle.jpg",
    },
    {
        title: "Inception",
        releaseYear: 2010,
        director: "Christopher Nolan",
        genre: "Science fiction",
        imageUrl: "res/artwork/inception.jpg",
    },
    {
        title: "The Intouchables",
        releaseYear: 2011,
        director: "Olivier Nakache & Éric Toledano",
        genre: "Comedy drama",
        imageUrl: "res/artwork/intouchables.jpg",
    },
    {
        title: "The Wolf of Wall Street",
        releaseYear: 2013,
        director: "Martin Scorsese",
        genre: "Biography",
        imageUrl: "res/artwork/wolf.webp",
    },
    {
        title: "Interstellar",
        releaseYear: 2014,
        director: "Christopher Nolan",
        genre: "Science fiction",
        imageUrl: "res/artwork/interstellar.jpg",
    },
];

export default movieList