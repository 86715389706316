const projectList = [
  {
    image: "res/img/reversi.png",
    title: "Reversi/Othello",
    technologies: "Java, JavaFX",
    shortDescription: "Java-based implementation of Reversi.",
    longDescription: `
      My first ever programming project. A Java-based implementation of the game Reversi. It
        has a few similarities to Go.
        The game can either be played with two players sharing one device and taking turns with placing pieces, or
        alone
        with the computer as an opponent.
        For this case, a basic AI is implemented that calculates the value of all potential moves and then chooses the
        best one.
        It is difficult to beat as a beginner, but it is definitely not perfect.
        The UI is build using a JavaFX application and its basic elements like shapes and buttons while the logic can
        be
        tracked in the console. It has a classic Java-App design and features some neat animations and an all-time
        highscore list.
        In this project, the focus was on method abstraction and modularity as well as quality assurance.
    `,
    links: [
      { label: "View on Github", url: "https://github.com/xZylote/Reversi-Othello-in-Java" },
    ],
  },
  {
    image: "res/img/scrabble.png",
    title: "Scrabble Clone",
    technologies: "HTML, CSS, JavaScript, Node.js, Socket.IO",
    shortDescription: "Web-based Scrabble game to play online with friends.",
    longDescription: `
        A web-based implementation of the classic Scrabble game. It enables playing over the internet with
        friends.

        During the pandemic it has been difficult to meet up with friends to play board games. A workaround is to do
        these sessions online but we soon noticed that there aren't any viable options to play Scrabble. So we built one
        ourselves...
        The frontend is build in classic HTML, JavaScript and CSS. It's kept simple yet offers all the functionality in a
        clear way. The underlying structure is provided by a grid into which the components such as the letters, the
        board, the chat and the controls are placed into.

        The backend is provided by a Node.js server. It waits for connections, notifies the players for changes and game
        events and enables communication between the players via the chat.
    `,
    links: [
      { label: "Leon Stier", url: "https://github.com/QuintessentialChicken" },
      { label: "View on Github", url: "https://github.com/xZylote/Scrabble" },
    ],
  },
  {
    image: "res/img/mood.png",
    title: "Mood Tracker",
    technologies: "Android, Java, HTML, CSS, JavaScript, Firebase",
    shortDescription: "Multi-component app for tracking mood and social data.",
    longDescription: `
        A multi-component implementation of a mood-tracking app for personal and research use in the field of
        psychology.
        It comprises an Anroid application, a web service for data analysis and backend functions and database in
        Firebase.
        The idea is that the user can track his mood on a daily basis and specify what special situations happened on
        that
        day.
        They can also invite friends to indicate them in a mood recording as a person they have been around on that
        day,
        thereby connecting data of multiple users.
        Special aspects of this project inlude the usage of the waterfall model and software requirements
        specifications
        with a strong focus on customer satisfaction, requirement engineering and PRD as well as thorough testing and
        documentation.
    `,
    links: [
      { label: "View on Github", url: "https://github.com/xZylote/MoodTrackerAndroid" },
    ],
  },
  {
    image: "res/svg/maxcut.svg",
    title: "Maximum Cut",
    technologies: "Java",
    shortDescription: "Java implementation of algorithms solving Maximum Cut.",
    longDescription: `
        A Java implementation of several different algorithms aimed at solving the NP-complete problem of
        partitioning a graphs vertices into two set such that the weight of the edges from one set to another is
        maximal.
        Interestingly, the minimization is not NP-hard.
        It comprises a heuristic, a parallelized and unparallelized approximation algorithm, as well as an exact
        solution.
        The goal of the project was to find out how fast the different algorithms were and how good the produced
        results are.
        I also evaluated the graph sizes that can be handled and the speed gain from parallelization.
        The graphs were sampled from a variety of data sources to accout for different real-world scenarios.
        The algorithms are used in semiconductor manufacturing for example.
    `,
    links: [
      { label: "View on Github", url: "https://github.com/xZylote/MaxCutRuntimes" },
    ],
  },
  {
    image: "res/img/bachelor.png",
    title: "Bachelor Project",
    technologies: "Angular, TypeScript, Python, SQL, PostGIS, Flask, Docker, D3.js",
    shortDescription: "Exploration of algorithms in data visualization.",
    longDescription: `
      A web-based application for analyzing and visualizing multivariate election data, focusing on data of the 2021 German federal election. It integrates political, socio-economic, and structural data using GIS and advanced color-based techniques like Radviz for high-dimensional visualization. Built with Python, PostgreSQL, JavaScript, and Docker, the tool offers dynamic maps, regression plots, and intuitive interfaces to explore voting patterns and their socio-economic drivers. It aims to support political research, campaign strategy, and retrospective policy evaluations while emphasizing usability and adaptability.
    `,
    links: [
      { label: "View on Github", url: "https://github.com/xZylote/VisualizingGermanPoliticalLandscape" },
      { label: "Download Thesis", url: "res/thesis.pdf" },
    ],
  },
  {
    image: "res/img/vic3.PNG",
    title: "Victoria 3 Production Calculator",
    technologies: "React",
    shortDescription: "Tool for planning industries in Victoria 3.",
    longDescription: `
      A web-based calculator that helps you plan your country's industry in the simulation game Victoria 3. By selecting 
      the appropriate production methods for various goods, you get a quick overview of the implications on supply and 
      demand. 

      This is especially complicated as in the Victorian century, through new innovation, the methods of production 
      change frequently, introducing new elements like oil, plastic, or electricity. The final version was only possible 
      thanks to the amazing work of Andres Meidla.
    `,
    links: [
      { label: "Try it out!", url: "https://vic3calc.web.app/" },
      { label: "Andres Meidla", url: "https://github.com/andresmeidla" },
      { label: "View on Github", url: "https://github.com/xZylote/vic3calc" },
    ],
  },
];

export default projectList