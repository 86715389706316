import React from "react";
import { Typography, Card, Col, Row } from "antd";
const { Title } = Typography;

const Movies = ({ movies }) => {
    return (
        <div style={{ padding: "20px" }}>
            <Title level={2} style={{ marginBottom: '30px', textAlign: 'center', color: 'var(--secondary-color)' }}>
                My Favorite Movies
            </Title>
            <Row gutter={[16, 16]}>
                {movies.map((movie) => (
                    <Col xs={12} md={8} lg={6} xl={4} key={movie.title}>
                        <Card style={{backgroundColor: "var(--octonary-color)"}}
                            cover={
                                <img style={{aspectRatio: '1000 / 1482', objectFit: 'cover'}}
                                    alt={movie.title}
                                    src={movie.imageUrl}
                                />
                            }
                        >
                            <Card.Meta
                                title={<span style={{ whiteSpace: "normal", wordWrap: "break-word"}}>
                                    {movie.title}
                                </span>}
                                description={
                                    <>
                                        <p style={{ color: "var(--tertiary-color)", margin: 0 }}>
                                            <strong>Director:</strong> {movie.director}
                                            <br />
                                            <strong>Release Year:</strong> {movie.releaseYear}
                                            <br />
                                            <strong>Genre:</strong> {movie.genre}
                                        </p>
                                    </>
                                }
                            />
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default Movies;
