const interests = [
    {
        id: 1,
        icon: 'experiment',
        title: 'Human-Computer Interaction',
        description: `Exploring the combination of wearable EEG devices and precise eye-tracking technologies to enable seamless brain-computer interaction. 
        Leveraging non-invasive methods to control systems intuitively. 
        Potential applications include accessibility enhancements for physically disabled individuals and advancing hands-free computer control technologies.`,
        keywords: ['Computational Neuroscience', 'EEG Devices', 'Eye-Tracking', 'Accessibility'],
    },
    {
        id: 2,
        icon: 'fund',
        title: 'Data Analysis and Visualization',
        description: `Focusing on advanced statistical methods and data visualization techniques to analyze and interpret large datasets. 
        Bridging ridge the gap between complex data and actionable insights through clear visual representation and robust analysis.
        Potential projects include developing intuitive tools for big data visualization and fostering the widespread adoption of data-driven 
        decision-making in industry and academia.`,
        keywords: ['Data Analysis', 'Data Visualization', 'Big Data', 'Statistical Methods'],
    },
    {
        id: 3,
        icon: 'bulb',
        title: 'Large Language Models',
        description: `Investigating the semantics and applications of high-dimensional vector embeddings in large language models (LLMs). 
        Exploring how embeddings capture nuanced meaning and can be utilized to develop an understanding of complex language structures.
        Potential projects include creating innovative solutions that combine LLM capabilities with data analytics for new insights.`,
        keywords: ['Generative AI', 'LLMs', 'Vector Embeddings', 'Semantic Analysis'],
    },
];



export default interests;