import React from 'react';
import { Row, Col, Typography, Card } from 'antd';
import myPicture from './res/img/profile.jpg';

const { Paragraph, Link } = Typography;

const About = () => (
  <Row justify="center" style={{ marginTop: '50px', padding: '0px' }}>
    <Col xs={24} sm={24} md={20}>
      <Card
        style={{
          borderRadius: '10px',
          padding: '20px',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Row
          gutter={[24, 24]} // Add space between columns
          align="middle"
          style={{
            flexDirection: 'row', // Default row layout for larger screens
          }}
        >
          {/* Image */}
          <Col xs={24} md={10} style={{ order: 1, textAlign: 'center' }}>
            <img
              src={myPicture}
              alt="Luca"
              style={{
                width: '100%',
                maxWidth: '250px',
                height: 'auto',
                objectFit: 'cover',
                borderRadius: '8px',
                marginBottom: '20px', // Add space below image for smaller screens
              }}
            />
          </Col>

          {/* Text Content */}
          <Col xs={24} md={14} style={{ order: 2 }}>
            <div style={{ textAlign: 'left' }}>
              <Paragraph>
                <strong>Welcome!</strong>
              </Paragraph>
              <Paragraph>
                My name is <strong>Luca</strong>. I studied <strong>Information Engineering</strong> at the <strong>University of Konstanz</strong> and
                wrote my bachelor's thesis at the chair of <strong>Data Analysis & Visualization</strong> in 2022. Feel free to
                check out their{' '}
                <Link
                  href="https://www.vis.uni-konstanz.de/en/teaching/projects"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  current projects
                </Link>.
              </Paragraph>
              <Paragraph>
                I am dedicated to advancing knowledge of data analytics with data-driven methodologies to gain new insights, support evidence-based research, and solve complex problems that help organizations unlock the full potential of their data.
              </Paragraph>
              <Paragraph>
                Below, you can find out about what I have worked on, as well as some personal information.
              </Paragraph>
              <Paragraph>
                <strong>E-mail:</strong> <a href="mailto:me@lucabosch.com">me@lucabosch.com</a>
                <br />
                <strong>LinkedIn:</strong>{" "}
                <a href="https://www.linkedin.com/in/lucabosch" target="_blank" rel="noopener noreferrer">
                  linkedin.com/in/lucabosch
                </a>
                <br />
                <strong>Phone:</strong> +49 170 158 33 12
              </Paragraph>
            </div>
          </Col>
        </Row>
      </Card>
    </Col>
  </Row>
);

export default About;
