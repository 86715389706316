import React from "react";
import { Collapse } from "antd";

const { Panel } = Collapse;

const Details = ({ title, children }) => {
  return (
    <Collapse style={{marginTop: '50px', backgroundColor: "var(--septary-color)", textAlign: "center"}}>
      <Panel header={title} key="1">
        {children}
      </Panel>
    </Collapse>
  );
};

export default Details;
