import React from 'react';
import { Layout } from 'antd';

const { Header } = Layout;

const AppHeader = () => (
  <Header
    style={{
      position: 'fixed',
      zIndex: 1,
      width: '100%',
      backgroundColor: 'var(--primary-color)',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 20px', // Adjust padding as needed
      height: '64px', // Ant Design's default Header height
    }}
  >
    <div
      className="logo"
      style={{
        color: 'white',
        fontWeight: 'bold',
        fontSize: '20px',
        lineHeight: '64px', // Ensures vertical alignment
      }}
    >
      Luca Bosch
    </div>
    <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
      <img
        src="./res/img/neon-sun.webp"
        alt="Button"
        style={{
          width: '40px',
          height: '40px',
          cursor: 'pointer',
        }}
        onClick={() => window.location.href = '/projects/rubiks/cube.html'}
      />
    </div>
  </Header>
);

export default AppHeader;
