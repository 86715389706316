import React from "react";
import { Typography, Card, Col, Row } from "antd";
const { Title } = Typography;

const Games = ({ games }) => {
    return (
        <div style={{ padding: "20px" }}>
            <Title level={2} style={{ marginBottom: '30px', textAlign: 'center', color: 'var(--secondary-color)' }}>
                My Favorite Games
            </Title>
            <Row gutter={[16, 16]}>
                {games.map((game) => (
                    <Col xs={24} sm={12} md={8} xl={4} key={game.title}>
                        <Card style={{backgroundColor: "var(--octonary-color)"}}
                            cover={
                                <img style={{aspectRatio: '100 / 120', objectFit: 'cover'}}
                                    alt={game.title}
                                    src={game.imageUrl}
                                />
                            }
                        >
                            <Card.Meta
                                title={<span style={{ whiteSpace: "normal", wordWrap: "break-word"}}>
                                    {game.title}
                                </span>}
                                description={
                                    <>
                                        <p style={{ color: "var(--tertiary-color)", margin: 0 }}>
                                            <strong>Developer:</strong> {game.developer}
                                            <br />
                                            <strong>Release Year:</strong> {game.releaseYear}
                                            <br />
                                            <strong>Genre:</strong> {game.genre}
                                        </p>
                                    </>
                                }
                            />
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default Games;
