const gameList = [
    {
        title: "The Elder Scrolls IV: Oblivion",
        releaseYear: 2006,
        developer: "Bethesda",
        genre: "Role-playing",
        imageUrl: "res/img/oblivion.webp",
    },
    {
        title: "Minecraft",
        releaseYear: 2011,
        developer: "Mojang",
        genre: "Sandbox",
        imageUrl: "res/img/minecraft.png",
    },
    {
        title: "Far Cry 3",
        releaseYear: 2012,
        developer: "Ubisoft",
        genre: "FPS",
        imageUrl: "res/img/farcry.jpg",
    },
    {
        title: "Europa Universalis IV",
        releaseYear: 2013,
        developer: "Paradox",
        genre: "Grand strategy",
        imageUrl: "res/img/eu4.webp",
    },
    {
        title: "Grand Theft Auto V",
        releaseYear: 2013,
        developer: "Rockstar North",
        genre: "Action",
        imageUrl: "res/img/gtav.jpg",
    },
    {
        title: "Factorio",
        releaseYear: 2020,
        developer: "Wube",
        genre: "Building simulation",
        imageUrl: "res/img/factorio.png",
    },
];

export default gameList;