import React from "react";
import { Layout } from "antd";
import "./Footer.css"; // Add your custom styles here

const { Footer } = Layout;

const AppFooter = () => {
    return (
        <Footer style={{ textAlign: "center", padding: "8px", backgroundColor: "var(--primary-color)" }}>
            <div className="contact-links">
                <a
                    className="contact-link linkedin"
                    href="https://www.linkedin.com/in/lucabosch/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    LinkedIn
                </a>
                <a
                    className="contact-link github"
                    href="https://github.com/xZylote"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    GitHub
                </a>
                <a
                    className="contact-link spotify"
                    href="https://open.spotify.com/user/1117606917"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Spotify
                </a>
            </div>
        </Footer>
    );
};

export default AppFooter;
