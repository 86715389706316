import React from 'react';
import { Card, Typography, Row, Col, Tag, List } from 'antd';

const { Title, Text, Paragraph, Link } = Typography;

const CV = () => {
    const cvData = {
        skills: {
            programmingLanguages: ['C++', 'CSS', 'Haskell', 'HTML', 'Java', 'JavaScript', 'Python', 'R', 'Shell', 'SQL'],
            appDevelopment: [
                'Angular',
                'Azure',
                'Docker',
                'Firebase',
                'Git',
                'React',
                'REST APIs',
                'Subversion',
                'Terraform',
                'Visual Studio Code',
            ],
            otherExpertise: ['Excel', 'LaTeX', 'Power BI', 'PowerPoint', 'SAP Analytics', 'Tableau'],
        },
        talks: [
            'Presented about LLM integration into financial reporting (2024, Frankfurt School of Finance)',
            'Lectured about process modeling within ERP systems and machine learning at Uni Gießen (2024)',
        ],
    };

    return (
        <section style={{ padding: '20px 20px' }}>
            <Row justify="center">
                <Col xs={24} sm={24} md={24} lg={20} xl={16}>
                    <Title level={2} style={{ marginBottom: '30px', textAlign: 'center', color: 'var(--secondary-color)' }}>
                        CV
                    </Title>

                    <Row gutter={[16, 16]}>
                        {/* Work Experience Section */}
                        <Col span={24}>
                            <Card title="2023 - Now (Work)">
                                <Title level={5} style={{ margin: 0 }}>Associate – Data & Analytics</Title>
                                <Link href="https://www.pwc.de/" style={{ color: 'var(--tertiary-color)', fontWeight: 'bold' }}>
                                    PwC | Düsseldorf, Germany
                                </Link>
                                <div>
                                    <ul>
                                        <li>Implementation of reporting systems (ETL, data warehousing and dashboarding)</li>
                                        <li>Development of machine learning web applications for finance controlling</li>
                                        <li>Data strategy consulting, user training, process automation, and go-to-market</li>
                                    </ul>
                                </div>
                            </Card>
                        </Col>

                        {/* Education Section */}
                        <Col span={24}>
                            <Card title="2017 - 2022 (Education)">
                                <Title level={5} style={{ margin: 0 }}>Bachelor of Science in Information Engineering</Title>
                                <Link href='https://www.uni-konstanz.de/' style={{ color: 'var(--tertiary-color)', fontWeight: 'bold' }}>
                                    University of Konstanz | Konstanz, Germany
                                </Link>
                                <div style={{ marginTop: '10px' }}>
                                    <Paragraph>
                                        <Text strong>Thesis:</Text> Visual Analytics for the Investigation of the Impact of Structural Constituency Data on Voting Behavior
                                    </Paragraph>
                                    <Paragraph>
                                        <Text strong>Key Subjects:</Text> <Tag color="var(--secondary-color)">Analysis and Linear Algebra</Tag> <Tag color="var(--secondary-color)">Algorithms and Data Structures</Tag> <Tag color="var(--secondary-color)">Analysis and Visualisation of Information</Tag> <Tag color="var(--secondary-color)">Interactive Systems</Tag> <Tag color="var(--secondary-color)">Algorithm Engineering</Tag>
                                    </Paragraph>
                                </div>
                            </Card>
                        </Col>

                        {/* Technologies Section */}
                        <Col span={24}>
                            <Card title="Skills">
                                <div>
                                    {cvData.skills.programmingLanguages.map((lang, index) => (
                                        <Tag key={index} color="var(--secondary-color)">{lang}</Tag>
                                    ))}
                                    <Tag color="var(--tertiary-color)">German</Tag>
                                    <Tag color="var(--tertiary-color)">English</Tag>
                                    <Tag color="var(--tertiary-color)">Spanish</Tag>
                                </div>
                                <div style={{ marginTop: '10px' }}>
                                    {cvData.skills.appDevelopment.map((skill, index) => (
                                        <Tag key={index} color="var(--secondary-color)">{skill}</Tag>
                                    ))}
                                </div>
                                <div style={{ marginTop: '10px' }}>
                                    {cvData.skills.otherExpertise.map((expertise, index) => (
                                        <Tag key={index} color="var(--secondary-color)">{expertise}</Tag>
                                    ))}
                                </div>
                            </Card>
                        </Col>

                        {/* Talks Section */}
                        <Col span={24}>
                            <Card title="Presentations">
                                <List>
                                    <List.Item style={{ padding: 0 }}>
                                        <Text style={{ color: 'var(--tertiary-color)', fontWeight: 'bold' }}>Dec 16, 2024 at Justus-Liebig-Universität Gießen</Text>: Process modeling and machine learning within ERP systems
                                    </List.Item>
                                    <List.Item style={{ padding: 0 }}>
                                        <Text style={{ color: 'var(--tertiary-color)', fontWeight: 'bold' }}>Oct 28, 2024 at Frankfurt School of Finance and Management</Text>: LLM integration into financial reporting
                                    </List.Item>
                                </List>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </section>
    );
};

export default CV;
