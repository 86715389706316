import React from 'react';
import { Row, Col, Typography, Card, Tag } from 'antd';
import { BulbOutlined, ExperimentOutlined, FundOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

const ResearchInterest = ({ interests }) => (
    <section id="research-interests" style={{ padding: '50px 20px' }}>
        {/* Section Header */}
        <Title level={2} style={{ textAlign: 'center', color: 'var(--secondary-color)' }}>
            Research Interests
        </Title>
        <Row gutter={[16, 32]} justify="center">
            <Col xs={24} sm={24} md={24} lg={20} xl={16}>
                <Paragraph style={{ textAlign: 'center', marginBottom: '40px', fontWeight: 'bold' }}>
                    These are the topics that I like to read about. I seek to uncover how systems work, sharing the journey of discovery, and making it accessible.
                    One of my main goals is to ignite people's curiosity for technology and make it leave a positive imprint on society.
                </Paragraph>
            </Col>
        </Row>

        {/* Research Categories */}
        <Row gutter={[16, 32]} justify="center">
            {interests.map((interest) => (
                <Col xs={24} sm={12} md={8} key={interest.id}>
                    <Card
                        bordered={false}
                        style={{
                            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                            borderRadius: '10px',
                            textAlign: 'center',
                        }}
                    >
                        {/* Icon */}
                        <div style={{ fontSize: '40px', marginBottom: '20px', color: 'var(--primary-color)' }}>
                            {interest.icon === 'bulb' && <BulbOutlined style={{ color: 'var(--tertiary-color)' }} />}
                            {interest.icon === 'experiment' && <ExperimentOutlined style={{ color: 'var(--tertiary-color)' }} />}
                            {interest.icon === 'fund' && <FundOutlined style={{ color: 'var(--tertiary-color)' }} />}
                        </div>

                        {/* Title */}
                        <Title level={4} style={{ marginBottom: '10px' }}>
                            {interest.title}
                        </Title>

                        {/* Short Description */}
                        <Paragraph>{interest.description}</Paragraph>

                        {/* Tags (Keywords or Subtopics) */}
                        <div style={{ marginTop: '10px' }}>
                            {interest.keywords.map((keyword, index) => (
                                <Tag key={index} color="var(--secondary-color)" style={{ marginBottom: '5px' }}>
                                    {keyword}
                                </Tag>
                            ))}
                        </div>
                    </Card>
                </Col>
            ))}
        </Row>
    </section>
);

export default ResearchInterest;
