const albumList = [
    {
        title: "The Four Seasons",
        artist: "Antonio Vivaldi",
        releaseYear: 1725,
        genre: "Classical",
        imageUrl: "res/img/fourseasons.jpg",
        fav: "10 - Winter",
        spotifyEmbedUrl:
            "https://open.spotify.com/embed/album/3vd4zvE1dSUdYirRVK8LSo?utm_source=generator&theme=0",
    },
    {
        title: "Animals",
        artist: "Pink Floyd",
        releaseYear: 1977,
        genre: "Progressive Rock",
        imageUrl: "res/img/animals.jpg",
        fav: "2 - Dogs",
        spotifyEmbedUrl:
            "https://open.spotify.com/embed/album/21jUB9RqplD6OqtsTjKBnO?utm_source=generator&theme=0",
    },
    {
        title: "Ten",
        artist: "Pearl Jam",
        releaseYear: 1991,
        genre: "Grunge",
        imageUrl: "res/img/ten.jpg",
        fav: "5 - Black",
        spotifyEmbedUrl:
            "https://open.spotify.com/embed/album/5B4PYA7wNN4WdEXdIJu58a?utm_source=generator&theme=0",
    },
    {
        title: "Nevermind",
        artist: "Nirvana",
        releaseYear: 1991,
        genre: "Grunge",
        imageUrl: "res/img/nevermind.jpg",
        fav: "1 - Smells Like Teen Spirit",
        spotifyEmbedUrl:
            "https://open.spotify.com/embed/album/2UJcKiJxNryhL050F5Z1Fk?utm_source=generator&theme=0",
    },
    {
        title: "Disconnected",
        artist: "Jan Blomqvist",
        releaseYear: 2018,
        genre: "Deep House",
        imageUrl: "res/img/disconnected.jpg",
        fav: "3 - Our Broken Mind Embassy",
        spotifyEmbedUrl:
            "https://open.spotify.com/embed/album/4CN2IioUNoLoInMTVRYNTw?utm_source=generator&theme=0",
    },
    {
        title: "Space Bob",
        artist: "Kalipo",
        releaseYear: 2018,
        genre: "Minimal",
        imageUrl: "res/img/spacebob.jpg",
        fav: "6 - Sommerbad Pankow",
        spotifyEmbedUrl:
            "https://open.spotify.com/embed/album/4GDowHaqNIWqEtjOARyPh3?utm_source=generator&theme=0",
    },
];

export default albumList;